<template>
    <div>
        <a-badge :count="tagCount">
            <a-button
                class="filter-icon"
                :size="filterButtonSize"
                @click="openFilter">
                <i class="fi fi-rr-filter"></i>
            </a-button>
        </a-badge>
        <a-drawer
            title=""
            placement="bottom"
            :visible="visible"
            class="filter"
            :class="showSearchInput && 'search_included'"
            :zIndex="zIndex"
            :afterVisibleChange="afterVisibleChange"
            :destroyOnClose="true"
            @close="visible = false">
            <div>
                <div v-show="filterLoading" class="flex justify-center p-2">
                    <a-spin />
                </div>
                <div v-show="!filterLoading" class="filter_drawer_content">
                    <div class="filter_title">
                        <a-button class="mr-1" type="link" @click="clearFilters()">
                            {{$t('clear')}}
                        </a-button>
                        <a-button class="mr-1" type="link" @click="visible = false">
                            Отмена
                        </a-button>
                    </div>
                    <div class="filter_header items-center justify-between">
                        <template v-if="showSearchInput" >
                            <div class="main_search w-full mb-2">
                                <a-input
                                    :value="filtersSearch"
                                    class="w-full"
                                    placeholder="поиск"
                                    @change="changeSearchInput">
                                    <template slot="suffix">
                                        <a-icon v-if="searchLoading" type="loading" />
                                        <i v-else class="fi fi-rr-search"></i>
                                    </template>
                                </a-input>
                            </div>
                        </template>
                        <div class="flex items-center w-full" v-if="checkExclude">
                            <a-button
                                class="filter_header_btn"
                                size="default"
                                icon="plus-circle"
                                :ghost="activeTab === 1 ? false : true"
                                @click="selectTab(1)"
                                type="ui">
                                Выбрать {{includeLenght > 0 ? `(${includeLenght})` : ''}}
                            </a-button>
                            <a-button
                                class="ml-2 filter_header_btn"
                                icon="minus-circle"
                                size="default"
                                :ghost="activeTab === 2 ? false : true"
                                @click="selectTab(2)"
                                type="ui">
                                {{$t('exclude')}} {{excludeLenght > 0 ? `(${excludeLenght})` : ''}}
                            </a-button>
                        </div>
                    </div>
                    <div class="filter_body_wrapper">
                        <div class="filter_body">
                            <div 
                                class="widget_item"
                                v-show="activeTab === 1" 
                                v-for="f in filterInclude" 
                                :key="f.name">
                                <WidgetsSwicth
                                    :container="$refs.drawer"
                                    :filter="f"
                                    :zIndex="zIndex"
                                    :injectSelectParams="injectSelectParams"
                                    :windowWidth="windowWidth"
                                    :name="name" />
                            </div>
                            <div 
                                class="widget_item"
                                v-show="activeTab === 2" 
                                v-for="f in filterExclude" 
                                :key="f.name">
                                <WidgetsSwicth
                                    :container="$refs.drawer"
                                    :filter="f"
                                    :zIndex="zIndex"
                                    :injectSelectParams="injectSelectParams"
                                    :windowWidth="windowWidth"
                                    :name="name" />
                            </div>
                        </div>
                    </div>
                    <div class="filter_footer_wrapper">
                        <div class="filter_footer flex items-center justify-between">
                            <a-button
                                icon="search"
                                type="primary"
                                size="large"
                                block
                                @click="setFilter()">
                                {{$t('find')}}
                            </a-button>
                            <!--<a-button class="ml-2" type="ui" @click="resetFilters()">
                                {{$t('reset')}}
                            </a-button>-->
                        </div>
                    </div>
                </div>
            </div>
        </a-drawer>
    </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import computedM from './mixins/computed'
import methodsM from './mixins/methods'
import propsM from './mixins/props'
import WidgetsSwicth from './widgets/WidgetsSwicth'
import eventBus from '@/utils/eventBus'
export default {
    props: {
        filterButtonSize: {
            type: String,
            default: 'large'
        }
    },
    components: {
        WidgetsSwicth
    },
    mixins: [
        methodsM,
        computedM,
        propsM
    ],
    data() {
        return {
            visible: false,
            filterLoading: false,
            tags: [],
            first: true,
            input: '',
            name: this.model,
            activeTab: 1,
            filterInclude: [],
            filterExclude: [],
            searchLoading: false,
            filterIncludeData: null
        }
    },
    computed: {
        tagCount() {
            if(this.filtersSearch?.length) {
                return this.tags.length + 1
            } else 
                return this.tags.length
        }
    },
    methods: {
        ...mapActions({
            getFiltersByKey: "filter/getFiltersByKey"
        }),
        openFilter() {
            this.openDrawer() 
        },
        openDrawer() {
            this.visible = true
        },
        close() {
            // const query = Object.assign({}, this.$route.query)
            // if(query.filter)
            //     delete query.filter
            
            // this.$router.push({query})
        },
        afterVisibleChange(visible) {
            if(!visible)
                this.close()
        },
    },
    async created(){
        this.filterLoading = true
        this.name = this.page_name

        if(!this.checkLoaded){ 
            await this.getFiltersByKey(
                {
                    name: this.model,
                    page_name: this.page_name,
                    params:  this.queryParams,
                    excludeFields: this.excludeFields
                })
        } 
        this.setFilterData()
        await this.tagGenerate()
        this.filterLoading = false
    },
    mounted() {
        eventBus.$on(`include_fields_${this.page_name}`, data => {
            this.filterIncludeData = data
            this.setFilter()
        })
        eventBus.$on(`send_include_fields_${this.page_name}`, data => {
            this.filterIncludeData = data
            this.setFilter()
        })
        eventBus.$on(`set_include_data_${this.page_name}`, data => {
            this.filterIncludeData = data
        })
    },
    beforeDestroy() {
        eventBus.$off(`include_fields_${this.page_name}`)
        eventBus.$off(`send_include_fields_${this.page_name}`)
        eventBus.$off(`set_include_data_${this.page_name}`)
    }

}
</script>

<style lang="scss">
.filter{
    .ant-drawer-content-wrapper {
        height: 100% !important;
    }
    .ant-drawer-body{
        padding: 0px;
    }
    .ant-drawer-content,
    .ant-drawer-wrapper-body{
        overflow: hidden;
    }
    .ant-drawer-header-no-title{
        display: none;
    }
}
</style>

<style scoped lang="scss">
.filter {
    $headerHeight: 50px;
    $footerHeight: 55px;
    $draweHeaderHeight: 40px;
    .filter_drawer_content{
        height: calc(100vh - $draweHeaderHeight);
    }

    .filter_title{
        height: 40px;
        border-bottom: 1px solid var(--borderColor);
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .filter_header{
        height: $headerHeight;
        padding: 8px 15px;
        border-bottom: 1px solid var(--borderColor);
        .filter_header_btn {
            flex-grow: 1;
        }
    }

    .filter_body_wrapper{
        height: calc(100vh - ($draweHeaderHeight + $headerHeight + $footerHeight)); /* Fallback for browsers that do not support Custom Properties */
        height: calc(var(--vh, 1vh) * 100 - ($draweHeaderHeight + $headerHeight + $footerHeight));

        overflow: auto;
    }
    .filter_body{
        padding: 15px;
    }
    .filter_footer_wrapper{
        position: relative;
        z-index: 5;
        height: $footerHeight;
        border-top: 1px solid var(--borderColor);
        background: var(--bgColor);
    }
    .filter_footer{
        padding: 8px 15px;
    }
}
.search_included {
    $headerHeight: 90px;
    $footerHeight: 55px;
    $draweHeaderHeight: 40px;
    .filter_drawer_content {
        height: calc(100vh - $draweHeaderHeight);
    }
    .filter_body_wrapper{
        height: calc(100vh - ($draweHeaderHeight + $headerHeight + $footerHeight)); /* Fallback for browsers that do not support Custom Properties */
        height: calc(var(--vh, 1vh) * 100 - ($draweHeaderHeight + $headerHeight + $footerHeight));
    }
    .filter_header {
        height: $headerHeight;
    }
    .filter_footer_wrapper {
        height: $footerHeight; 
    }
}
.filter-icon {
    display: flex;
    align-items: center;
}
.widget_item {
    &:not(:last-child) {
        margin-bottom: 0.75rem;
    }
}
</style>
